import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor() {
  }

  credentials;

  randNum = Math.round(Math.random() * 10000);

  setRandNum() {
    this.randNum = Math.round(Math.random() * 10000);
  }

  setCredentials(credentials) {
    this.credentials = credentials;
  }

  setDefault(x) {
    this.credentials = {
      clientID: x.username,
      avatar: x.avatar,
      firstName: x.first_name,
      lastName: x.last_name,
      email: x.email,
      password: '',
      confirmPassword: '',
      phone: x.phone,
      location: x.location
    };
  }

}
