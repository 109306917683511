import {Component, AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {AuthenticationService} from '../_services';
import {User} from '../_models';
import {environment} from '../../../environments/environment';
import {AccountService} from '../../account/account.service';
import {HttpClient} from '@angular/common/http';

declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent {

  environmentApiUrl = environment.apiUrl;

  currentUser: User;

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;

  // This is for Notifications
  notifications: Object[];
  numOfNewNotifications = 0;

  constructor(
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private router: Router,
    public profileAvatarPictureUpdate: AccountService,
    public http: HttpClient
  ) {
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      if (x) {
        this.profileAvatarPictureUpdate.setDefault(x);
      }
    });

    // this.getNotifications();
    // const interval = setInterval(() => {
    //   this.getNotifications();
    // }, 5000);
  }

  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM'
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  logOut() {
    this.authenticationService.logout();
  }

  myProfile() {
    this.router.navigate(['/account/profile']);
  }

  getNotifications() {
    this.http.get<any>(`${environment.apiUrl}/api/notifications`).subscribe(response => {
      const resp = response.filter((item) => {
        return item.new.includes(this.currentUser.username);
      });
      this.notifications = resp;
      this.numOfNewNotifications = resp.length;
    });
  }

  removeNotifications() {
    // this.http.get<any>(`${environment.apiUrl}/api/notifications-change-status/${this.currentUser.username}`).subscribe(data => {
    //   console.log('status changed');
    // });
    // this.numOfNewNotifications = 0;
  }

}
