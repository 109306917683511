import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';

import {AlertService, AuthenticationService} from '../shared/_services';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {PermissionsService} from '../shared/_services/permissions.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  loginFormDisplay = true;
  recoverFormDisplay = false;

  emailAddress: String = '';

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  usernameBlur = false;
  passwordBlur = false;
  showAlert = false;
  returnUrl: string;

  permissions: any;

  resetDisabled = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private http: HttpClient,
    private permissionsData: PermissionsService,
    private toastr: ToastrService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/dashboard']);
    }
  }

  ngOnInit() {
    this.permissionsData.currentUserPermissionsData.subscribe(data => this.permissions = data);

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
  }

  showRecoverForm() {
    this.loginFormDisplay = !this.loginFormDisplay;
    this.recoverFormDisplay = !this.recoverFormDisplay;
  }

  onBlur(input) {
    if (input === 'username') {
      this.usernameBlur = true;
    }
    if (input === 'password') {
      this.passwordBlur = true;
    }
  }

  toggleAlert() {
    if (this.showAlert === true) {
      this.showAlert = false;
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      // this.alertService.error('Invalid Form!');
      return;
    }

    this.loading = true;
    this.showAlert = true;

    this.authenticationService.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this.http.get(`${environment.apiUrl}/api/user-permissions/${this.f.username.value}`)
            .subscribe((res) => {
              this.permissionsData.changeUserPermissionsData(res);
            }, () => {
              this.authenticationService.logout();
              this.alertService.error('Access denied!');
              this.loading = false;
            });
          this.router.navigate([this.returnUrl]);
        },
        error => {
          if (error !== 'Unknown Error') {
            this.alertService.error(error);
            this.loading = false;
          } else {
            this.alertService.error('Server maintenance in progress, please try again later.');
            this.loading = false;
          }
        });
  }

  passwordReset() {
    this.resetDisabled = true;
    this.http.post<any>(`${environment.apiUrl}/authentication/passwordReset`, {
      email: this.emailAddress
    })
      .subscribe((resp) => {
        this.toastr.info(resp.message, 'Info');
        this.loginFormDisplay = !this.loginFormDisplay;
        this.recoverFormDisplay = !this.recoverFormDisplay;
        this.resetDisabled = false;
      }, (err) => {
        this.toastr.error('Unknow error occured', 'Error');
      });
  }

}
