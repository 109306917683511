import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {Routes, RouterModule} from '@angular/router';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {FullComponent} from './layouts/full/full.component';
import {BlankComponent} from './layouts/blank/blank.component';

import {NavigationComponent} from './shared/header-navigation/navigation.component';
import {SidebarComponent} from './shared/sidebar/sidebar.component';
import {BreadcrumbComponent} from './shared/breadcrumb/breadcrumb.component';

import {Approutes} from './app-routing.module';
import {AppComponent} from './app.component';
import {SpinnerComponent} from './shared/spinner.component';

import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {PERFECT_SCROLLBAR_CONFIG} from 'ngx-perfect-scrollbar';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';

import {ErrorInterceptor, JwtInterceptor} from './shared/_helpers';
import {AlertComponent} from './shared/_components';
import {LoginComponent} from './login';
import {LoadingPageSpinnerComponent} from './shared/loading-page-spinner/loading-page-spinner.component';
import {ToastrModule} from 'ngx-toastr';
import 'hammerjs';
import {MATERIAL_SANITY_CHECKS} from '@angular/material';
import {MaterialModule} from './shared/material-module/material.module';
import {MaterialDialogComponent} from './shared/material-dialog/material-dialog.component';
import {LoadingDataSpinnerModule} from './shared/loading-data-spinner/loading-data-spinner.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20
};

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    BlankComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    AlertComponent,
    LoginComponent,
    LoadingPageSpinnerComponent,
    MaterialDialogComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule.forRoot(),
    RouterModule.forRoot(Approutes),
    ToastrModule.forRoot(),
    PerfectScrollbarModule,
    LoadingDataSpinnerModule
  ],
  entryComponents: [
    MaterialDialogComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {
      provide: MATERIAL_SANITY_CHECKS,
      useValue: true
    }
  ],
  bootstrap: [AppComponent],
  exports: [BrowserAnimationsModule]
})
export class AppModule {
}
