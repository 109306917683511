﻿import {Injectable, OnInit} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot} from '@angular/router';

import {AuthenticationService} from '../_services';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {PermissionsService} from '../_services/permissions.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  permissions: any;

  constructor(
    public router: Router,
    private authenticationService: AuthenticationService,
    private http: HttpClient,
    private permissionsData: PermissionsService
  ) {
    this.permissionsData.currentUserPermissionsData.subscribe(data => this.permissions = data);
  }

  canActivate(route: ActivatedRouteSnapshot) {
    // console.log(this.permissions);
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      const currentRoute = route['_routerState'].url.split('/');
      const item = currentRoute[currentRoute.length - 2];
      const subItem = currentRoute[currentRoute.length - 1];
      // console.log(subItem);
      if (subItem !== 'dashboard' && subItem !== 'profile' &&
        this.permissions.type !== 0 &&
        (!this.permissions['permissions'][item]['read'] ||
          !this.permissions['permissions'][item][subItem]['read'])) {
        // permission not granted so redirect to home page
        this.router.navigate(['/dashboard']);
        return true;
      }
      this.http.post<any>(`${environment.apiUrl}/check-token`, {},
        {
          headers: new HttpHeaders({
            'Authorization': `${currentUser.token}`
          })
        })
        .subscribe(() => {
          return true;
        }, () => {
          this.authenticationService.logout();
          return false;
        });
      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page
    this.router.navigate(['/login']);
    return false;
  }
}
