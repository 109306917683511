import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {FullComponent} from './layouts/full/full.component';
import {BlankComponent} from './layouts/blank/blank.component';
import {AuthGuard} from './shared/_guards';
import {LoginComponent} from './login';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    // canActivate: [AuthGuard],
    children: [
      {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: './dashboard/dashboard.module#DashboardModule' // lazy load
        // children: [ // startup load
        //   {path: 'classic', component: Dashboard1Component},
        //   {path: 'analytical', component: Dashboard2Component},
        //   {path: 'modern', component: Dashboard3Component}
        // ]
      },
      {
        path: 'account',
        canActivate: [AuthGuard],
        loadChildren: './account/account.module#AccountModule'
      },
      {
        path: 'administration',
        canActivate: [AuthGuard],
        loadChildren: './administration/administration.module#AdministrationModule'
      },
      {
        path: 'servers',
        canActivate: [AuthGuard],
        loadChildren: './servers/servers.module#ServersModule'
      },
      {
        path: 'customers',
        canActivate: [AuthGuard],
        loadChildren: './customers/customers.module#CustomersModule'
      },
      {
        path: 'sellers',
        canActivate: [AuthGuard],
        loadChildren: './sellers/sellers.module#SellersModule'
      },
      {
        path: 'ticketing',
        canActivate: [AuthGuard],
        loadChildren: './ticketing/ticketing.module#TicketingModule'
      },
      {
        path: 'monitoring',
        canActivate: [AuthGuard],
        loadChildren: './monitoring/monitoring.module#MonitoringModule'
      },
      {
        path: 'campaign',
        canActivate: [AuthGuard],
        loadChildren: './campaign/campaign.module#CampaignModule'
      }
    ]
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {path: 'login', component: LoginComponent},
      {
        path: 'errors',
        loadChildren:
          './errors/errors.module#ErrorsModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/dashboard'
  }
];
