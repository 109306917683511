import {Component, OnInit} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent} from '@angular/router';
import 'rxjs/add/operator/filter';

import {AuthenticationService} from './shared/_services';
import {User} from './shared/_models';
import {PermissionsService} from './shared/_services/permissions.service';
import {environment} from '../environments/environment';
import {HttpClient} from '@angular/common/http';
import {AccountService} from './account/account.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  currentUser: User;
  currentPermissions: any;
  loading = true;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private permissionsData: PermissionsService,
    private accountService: AccountService,
    private http: HttpClient
  ) {
    this.permissionsData.currentUserPermissionsData.subscribe(data => this.currentPermissions = data);
    this.authenticationService.currentUser.subscribe(
      x => {
        this.currentUser = x;
        if (x) {
          this.accountService.setDefault(x);
        }
      }
    );

    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
      if (this.router.url === '/campaign/mailer') {
        $('.page-title').hide();
      } else {
        $('.page-title').show();
      }
    });
  }

  ngOnInit() {
    if (this.authenticationService.currentUserValue) {
      this.http.get(`${environment.apiUrl}/api/user-permissions/${this.currentUser.username}`)
        .subscribe((res) => {
          this.permissionsData.changeUserPermissionsData(res);
        }, () => {
          this.authenticationService.logout();
          this.loading = false;
        });
    }
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
    }
    if (event instanceof NavigationEnd) {
      this.loading = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false;
    }
    if (event instanceof NavigationError) {
      this.loading = false;
    }

    // this.router.events
    //   .filter(e => event instanceof NavigationEnd)
    //   .subscribe(e => {
    //     if (e instanceof RouterEvent) {
    //       console.log(e.url);
    //     }
    //   });
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
