import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-page-spinner',
  templateUrl: './loading-page-spinner.component.html',
  styleUrls: ['./loading-page-spinner.component.css']
})
export class LoadingPageSpinnerComponent {

  constructor() { }

}
