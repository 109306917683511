import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-deactivate-dialog',
  templateUrl: './material-dialog.component.html',
  styleUrls: ['./material-dialog.component.css']
})
export class MaterialDialogComponent {
  htmlContent: string;
  contentString = false;
  actionButtons = false;

  constructor(
    public dialogRef: MatDialogRef<MaterialDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (typeof data.content !== 'undefined') {
      this.contentString = true;
    }
    if (typeof data.actions !== 'undefined') {
      this.actionButtons = true;
    }
  }

  save() {
    this.dialogRef.close('save');
  }

  close() {
    this.dialogRef.close('close');
  }
}
