import {NgModule} from '@angular/core';
import {LoadingDataSpinnerComponent} from './loading-data-spinner.component';

@NgModule({
  declarations: [
    LoadingDataSpinnerComponent
  ],
  exports: [LoadingDataSpinnerComponent]
})
export class LoadingDataSpinnerModule {
}
