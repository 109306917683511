import {RouteInfo} from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  // {
  //   path: '',
  //   title: 'Statistics',
  //   icon: 'mdi mdi-chart-areaspline',
  //   class: 'nav-small-cap #statistics',
  //   extralink: true,
  //   submenu: []
  // },
  {
    path: '/dashboard',
    title: 'Dashboard',
    icon: 'mdi mdi-view-dashboard #dashboard',
    class: '',
    extralink: false,
    submenu: []
  },
  // {
  //   path: '',
  //   title: 'Access Control',
  //   icon: 'mdi mdi-glassdoor',
  //   class: 'nav-small-cap #access-control',
  //   extralink: true,
  //   submenu: []
  // },
  {
    path: '',
    title: 'Campaign',
    icon: 'mdi mdi-bandcamp #campaign',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/campaign/mailer',
        title: 'Email',
        icon: 'mdi mdi-email #mailer',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Customers',
    icon: 'mdi mdi-account-box #customers',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/customers/clients',
        title: 'Clients',
        icon: 'mdi mdi-account-star-variant #clients',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/customers/demo-clients',
        title: 'Demo Clients',
        icon: 'mdi mdi-account-circle #demo-clients',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/customers/demo-requests',
        title: 'Demo Requests',
        icon: 'mdi mdi-account-location #demo-requests',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Sellers',
    icon: 'mdi mdi-account-box-outline #sellers',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/sellers/commission',
        title: 'Commission',
        icon: 'mdi mdi-credit-card-multiple #commission',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  // {
  //   path: '',
  //   title: 'Monitoring',
  //   icon: 'mdi mdi-monitor #monitoring',
  //   class: 'nav-small-cap',
  //   extralink: true,
  //   submenu: []
  // },
  {
    path: '',
    title: 'Monitoring',
    icon: 'mdi mdi-monitor #monitoring',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/monitoring/logs',
        title: 'Logs',
        icon: 'mdi mdi-file #logs',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/monitoring/live-logs',
        title: 'Live Logs',
        icon: 'mdi mdi-file #logs',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  // {
  //   path: '',
  //   title: 'Support',
  //   icon: 'mdi mdi-message #support',
  //   class: 'nav-small-cap',
  //   extralink: true,
  //   submenu: []
  // },
  {
    path: '',
    title: 'Ticketing',
    icon: 'mdi mdi-book-open-page-variant #ticketing',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/ticketing/tickets',
        title: 'Tickets',
        icon: 'mdi mdi-ticket-confirmation #tickets',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  // {
  //   path: '',
  //   title: 'Settings',
  //   icon: 'mdi mdi-settings #settings',
  //   class: 'nav-small-cap',
  //   extralink: true,
  //   submenu: []
  // },
  {
    path: '',
    title: 'Administration',
    icon: 'mdi mdi-account-settings #administration',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/administration/admins',
        title: 'Admins',
        icon: 'mdi mdi-account-edit #admins',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/administration/users',
        title: 'Users',
        icon: 'mdi mdi-account #users',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/administration/permissions',
        title: 'Permissions',
        icon: 'mdi mdi-account-settings-variant #permissions',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Servers',
    icon: 'mdi mdi-server-network #servers',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/servers/server-list',
        title: 'Server List',
        icon: 'mdi mdi-server #server-list',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  }
];
